'use client';

import React from 'react';
import Image from 'next/image';
import { Tabs, TabsContent, TabsList, TabsTrigger } from './ui/tabs';
// import { TabsContent } from './ui/tab';
// import { Tabs } from './ui/tabs';
// import { Tabs } from './ui/tabs';
// import { Tabs } from './ui/tabs';

import {
  User2,
  MailIcon,
  HomeIcon,
  PhoneCall,
  GraduationCap,
  Calendar,
  Briefcase,
  User,
} from 'lucide-react';
import DevImg from './DevImg';

const infoData = [
  {
    icon: <User2 size={20} />,
    text: 'Jérémie Deldem',
  },
  {
    icon: <PhoneCall size={20} />,
    text: '(+33)6.95.14.84.54',
  },
  {
    icon: <MailIcon size={20} />,
    text: 'jeremie.deldem@gmail.com',
  },
  {
    icon: <Calendar size={20} />,
    text: '34 ans',
  },
  {
    icon: <GraduationCap size={20} />,
    text: 'Master 2',
  },
  {
    icon: <HomeIcon size={20} />,
    text: 'Occitanie, France',
  },
];

const qualificationData = [
  {
    title: 'experiences',
    data: [
      {
        company: 'The Hacking Project',
        qualification: 'Staff pédagogique & Customer Success',
        years: '2023',
      },
      {
        company: 'Freelance',
        qualification:
          'Développeur Web Fullstack et Technicien informatique',
        years: '2022',
      },
    ],
  },
  {
    title: 'etudes',
    data: [
      // {
      //   university: 'Montpellier 2',
      //   qualification: 'Licence',
      //   years: '2008-2011',
      // },
      // {
      //   university: 'Perpignan UPVD',
      //   qualification: 'Master',
      //   years: '2015',
      // },
      {
        university: 'The Hacking Project',
        qualification: 'Diplôme de Développeur Web - RNCP37805',
        years: '2021',
      },
      {
        university: 'OpenClass Rooms',
        qualification: 'Diplôme de Développeur concepteur logiciel - RNCP27099',
        years: '2023',
      },
    ],
  },
];
const skillData = [
  {
    title: 'Compétences',
    data: [
      {
        name: 'Javascript, React.JS, Next.JS, GSAP, Framer-Motion',
      },
      {
        name: 'développeur Front-end & Back-end ',
      },
      {
        name: 'Capacité à concevoir des architectures évolutives pour accompagner la croissance future du projet.',
      },
      {
        name: 'Compréhension approfondie des meilleures pratiques d\'optimisation pour garantir des performances maximales.',
      },
    ],
  },
  {
    title: 'Outils',
    data: [
      {
        imgPath: '/about/vscode.svg',
      },
      {
        imgPath: '/about/figma.svg',
      },
      {
        imgPath: '/about/notion.svg',
      },
      {
        imgPath: '/about/photoshop.svg',
      },
    ],
  },
];

const About = () => {
  const getData = (arr, title) => {
    return arr.find((item) => item.title === title);
  };
  return (
    <section className='xl:h-[860px] pb-12 xl:py-24'>
      <div className='container mx-auto'>
        <h2 className='section-title mb-8 xl:mb-16 text-center mx-auto'>
          À propos de moi
        </h2>
        <div className='flex flex-col xl:flex-row'>
          {/* AVATAR2 */}
          <div className='hidden xl:flex flex-1 relative'>
            <DevImg
              containerStyles='bg-about_shape_light dark:bg-about_shape_dark size-[505px] bg-no-repea relative'
              imgSrc='/about/avatar.png'
            />
          </div>
          {/* TABS */}
          <div className='flex-1 '>
            <Tabs defaultValue='perso'>
              <TabsList className='w-full grid xl:grid-cols-3 xl:max-w-[620px] dark:border-none mx-auto my-auto '>
                <TabsTrigger className='w-[162px] xl:w-auto' value='perso'>
                  Infos perso
                </TabsTrigger>
                <TabsTrigger className='w-[162px] xl:w-auto' value='qualif'>
                  Qualifications
                </TabsTrigger>
                <TabsTrigger className='w-[162px] xl:w-auto' value='skills'>
                  Compétences
                </TabsTrigger>
              </TabsList>
              <div className='text-lg mt-12 xl:mt-8'>
                {/* PERSO */}
                <TabsContent value='perso'>
                  <div className='text-center xl:text-left'>
                    <h3 className='h3 mb-4'>Un service de qualité</h3>
                    <p className='subtitle max-w-xl mx-auto xl:mx-0 '>
                      Je suis spécialisé dans la création de sites web intuitifs,
                      modernes et professionnels. Avec une passion pour
                      l'innovation et un engagement pour la qualité, je suis
                      prêt à apporter mon expertise pour faire de votre projet
                      une réussite.
                    </p>
                    {/* ICONS  */}
                    <div className='grid xl:grid-cols-2 gap-4 mb-12 '>
                      {infoData.map((item, index) => {
                        return (
                          <div
                            className='flex items-center gap-x-4 mx-auto xl:mx-0'
                            key={index}
                          >
                            <div className='text-primary'>{item.icon}</div>
                            <div>{item.text}</div>
                          </div>
                        );
                      })}
                    </div>
                    {/* LANGUAGES  */}
                    <div className='flex flex-col gap-y-2'>
                      <div className='text-primary'>Langues</div>
                      <div className='border-b border-border'></div>
                      <div>Français, Anglais</div>
                    </div>
                  </div>
                </TabsContent>
                {/* QUALIFICATIONS  */}
                <TabsContent value='qualif'>
                  <div className='flex flex-col gap-y-6'>
                    <h3 className='h3 mx-8 text-center xl:text-left'>
                      Mon parcours
                    </h3>
                    {/* EXPERIENCE */}
                    <div className='grid md:grid-cols-2 gap-y-8 gap-x-8'>
                      <div className='flex flex-col gap-y-6'>
                        <div className='flex gap-x-4 items-center text-[22px] text-primary'>
                          <Briefcase size={28} />
                          <h4 className='capitalize font-medium'>
                            {getData(qualificationData, 'experiences').title}
                          </h4>
                        </div>
                        <div className='flex flex-col gap-y-8'>
                          {getData(qualificationData, 'experiences').data.map(
                            (item, index) => {
                              const { company, qualification, years } = item;
                              return (
                                <div
                                  key={index}
                                  className='flex gap-x-8 group bg-yellow-100 rounded-xl p-4 border-2 border-[#FA6E58] shadow-[5px_5px_0px_0px_#FA6E58] hover:translate-y-1 hover:translate-x-1 transition-all hover:!shadow-none dark:bg-[#322149] dark:border-[#6D28D9] dark:shadow-[5px_5px_0px_0px_#6D28D9]          '
                                >
                                  <div className='w-[1px] h-[84px] bg-primary relative mt-2'>
                                    <div className='size-[11px] bg-primary rounded-full absolute -left-[5px] group-hover:translate-y-[84px] transition-all duration-500'></div>
                                  </div>
                                  <div>
                                    <div className='font-semibold text-xl leading-none mb-2'>
                                      {company}
                                    </div>
                                    <div className='text-lg leading-none text-muted-foreground mb-4'>
                                      {qualification}
                                    </div>
                                    <div className='text-base font-medium'>
                                      {years}
                                    </div>
                                  </div>
                                </div>
                              );
                            }
                          )}
                        </div>
                      </div>
                      {/* ETUDES  */}
                      <div>
                        <div className='flex flex-col gap-y-6'>
                          <div className='flex gap-x-4 items-center text-[22px] text-primary'>
                            <GraduationCap size={28} />
                            <h4 className='capitalize font-medium'>
                              {getData(qualificationData, 'etudes').title}
                            </h4>
                          </div>
                          <div className='flex flex-col gap-y-8'>
                            {getData(qualificationData, 'etudes').data.map(
                              (item, index) => {
                                const { university, qualification, years } =
                                  item;
                                return (
                                  <div
                                    key={index}
                                    className='flex gap-x-8 group bg-yellow-100 rounded-xl p-4 border-2 border-[#FA6E58] shadow-[5px_5px_0px_0px_#FA6E58] hover:translate-y-1 hover:translate-x-1 transition-all hover:!shadow-none dark:bg-[#322149] dark:border-[#6D28D9] dark:shadow-[5px_5px_0px_0px_#6D28D9]          '
                                  >
                                    <div className='w-[1px] h-[84px] bg-primary relative mt-2'>
                                      <div className='size-[11px] bg-primary rounded-full absolute -left-[5px] group-hover:translate-y-[84px] transition-all duration-500'></div>
                                    </div>
                                    <div>
                                      <div className='font-semibold text-xl leading-none mb-2'>
                                        {university}
                                      </div>
                                      <div className='text-lg leading-none text-muted-foreground mb-4'>
                                        {qualification}
                                      </div>
                                      <div className='text-base font-medium'>
                                        {years}
                                      </div>
                                    </div>
                                  </div>
                                );
                              }
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </TabsContent>
                <TabsContent value='skills'>
                  <div className='text-center'>
                    <h3 className='h3 mb-6'>Compétences Fullstack</h3>
                    {/* SKILLS  */}
                    <div className='mb-8'>
                      <div>
                        {getData(skillData, 'Compétences').data.map(
                          (item, index) => {
                            const { name } = item;
                            return (
                              <div
                                className='w-3/4  mx-auto'
                                key={index}
                              >
                                <div className='font-medium border-b-2 py-2 border-primary'>
                                  {name}
                                </div>
                              </div>
                            );
                          }
                        )}
                      </div>
                    </div>
                    {/* OUTILS  */}
                    <div>
                      <h4 className='text-xl font-semibold mb-2'>
                        Outils
                      </h4>
                      <div className='border-b border-border mb-4'></div>
                      <div className='flex gap-x-8 justify-center'>
                        {getData(skillData, 'Outils').data.map(
                          (item, index) => {
                            const { imgPath } = item;
                            return (
                              <div key={index}>
                                <Image
                                  src={imgPath}
                                  width={48}
                                  height={48}
                                  alt='icons'
                                  priority
                                />
                              </div>
                            );
                          }
                        )}
                      </div>
                    </div>
                  </div>
                </TabsContent>
              </div>
            </Tabs>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
