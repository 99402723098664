import Link from 'next/link';
import Image from 'next/image';
import { Card, CardHeader } from './ui/card';
import { Github, MoveDiagonal } from 'lucide-react';
import { Badge } from './ui/badge';

const ProjectCard = ({ project }) => {
  return (
    <Card className='group overflow-hidden relative'>
      <CardHeader className='p-0'>
        <div className='relative w-full h-[235px] flex items-center justify-center bg-tertiary dark:bg-secondary/40'>
          <Image
            className='absolute bottom-0 shadow-2xl'
            src={project.image}
            width={247}
            height={250}
            alt=''
            priority
          />
          {project.link !== '/' && (
  <Link
    href={project.link}
    className='hidden md:flex bg-secondary w-[54px] h-[54px] rounded-full justify-center items-center scale-0 opacity-0 group-hover:scale-100 group-hover:opacity-100 transition-all duration-200'
  >
    <MoveDiagonal className='text-black dark:text-white' />
  </Link>
)}
          {project.prod && (
  <a
    href={project.prod}
    target='_blank'
    rel="noopener noreferrer"
    className='bg-secondary w-[54px] h-[54px] rounded-full flex justify-center items-center scale-0 opacity-0 group-hover:scale-100 group-hover:opacity-100 transition-all duration-200'
  >
    <MoveDiagonal className='text-black dark:text-white' />
  </a>
)}

        </div>
      </CardHeader>
      <div className='h-full px-8 py-6'>
        <Badge className='uppercase text-sm font-medium mb-0 absolute top-4 left-4'>
          {project.category}
        </Badge>
        <h4 className='h4 mb-1'>{project.name}</h4>
        <p className='text-muted-foreground text-lg'>{project.description}</p>
      </div>
    </Card>
  );
};

export default ProjectCard;
