// Modal.jsx

import React from 'react';
import { Undo2, XSquare } from 'lucide-react';
import Image from 'next/image'

const Modal = ({ onClose, children }) => {
  return (
    <div className='z-[21] fixed top-0 left-0 w-full h-full flex items-center justify-center backdrop-blur-xl bg-opacity-50 rounded'>
      <div className='bg-gradient-to-r from-primary/10 via-secondary/30 p-4 rounded-md w-[90%] xl:w-[60%] h-[80%] md:h-auto md:max-h-[80%] overflow-auto mobile-scrollbar mb-8 xl:mt-24 border-b-4 border-r-4 my-12 md:mt-16  !border-2 dark:!border-[#6D28D9] dark:!shadow-[5px_5px_0px_0px_#6D28D9] !border-[#FA6E58] !shadow-[5px_5px_0px_0px_#FA6E58]'>
        <div className='flex justify-end mb-4'>
          <button
            onClick={onClose}
            className='text-[#FE705A] hover:text-[#DF5446] dark:!text-primary dark:hover:!text-[#6D28D9] hover:scale-110 size-12 transition-all duration-50'
          >
            <XSquare size={48} />
          </button>
        </div>
        <div className='flex justify-center mx-auto'>
          <Image src='/cv.webp' width={1000} height={1000} alt='mon cv' />
        </div>
        <div className='flex justify-center '>
          <button
            onClick={onClose}
            className=' text-black dark:text-white hover:scale-125 transition-all duration-500  animate-bounce text-6xl my-4 xl:mt-6 '
          >
            <Undo2 />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Modal;
