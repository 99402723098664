"use client";

import { RiGithubFill, RiLinkedinFill, RiInstagramFill } from "react-icons/ri";
import Link from "next/link";

const icons = [
    {
        path: "https://github.com/8UK0W5K1/",
        name: <RiGithubFill />,
        label: "Github", // Ajout de l'aria-label pour Github
    },
    {
        path: "https://www.linkedin.com/in/jeremie-deldem/",
        name: <RiLinkedinFill />,
        label: "LinkedIn", // Ajout de l'aria-label pour LinkedIn
    },
    // {
    //   path: '/',
    //   name: <RiInstagramFill />,
    //   label: 'Instagram', // Ajout de l'aria-label pour Instagram (si nécessaire)
    // },
];

const Socials = ({ containerStyles, iconsStyles }) => {
    return (
        <div className={`${containerStyles}`}>
            {icons.map((icon, index) => {
                return (
                    <a
                        target="_blank"
                        href={icon.path}
                        key={index}
                        rel="noopener noreferrer"
                        aria-label={icon.label} // Ajout de l'attribut aria-label
                    >
                        <div className={`${iconsStyles}`}>{icon.name}</div>
                    </a>
                );
            })}
        </div>
    );
};

export default Socials;
