export const projectData = [
  {
    image: '/works/mrC.webp',
    category: 'Entreprenariat',
    name: 'Mr.Computer',
    description:
      'Site vitrine de mon activité de dépannage, maintenance et réparation informatique et mobile. Diverses animations : logo, image de fond, transitions, etc.',
    link: '/',
    prod: 'https://mrcomputer.fr/',
    github: '/',
  },
  {
    image: '/works/tropico.webp',
    category: 'Marketplace',
    name: "Tropic'O",
    description:
      'Site de vente en ligne de plantes rares et exotiques.',
    link: '/',
    prod: 'https://tropico-store.vercel.app',
    github: '/',
  },
  {
    image: '/works/sanae.webp',
    category: 'nouveaux',
    name: 'SANAE - RSE',
    description:
      "Site vitrine d'un cabinet de conseil, de formation et d’ingénierie dans le domaine de la RSE",
    link: '/',
    prod: 'https://sanae-rse.vercel.app',
    github: '/',
  },
  {
    image: '/works/admin.webp',
    category: 'Administration',
    name: 'Dashboard - Admin',
    description:
      "Plateforme de gestion privée d'une boutique de vente en ligne.",
    link: '/',
    prod: '',
    github: '/',
  },
  {
    image: '/works/zoomParallax.gif',
    category: 'nouveaux',
    name: 'Gallerie: zoom, fixe & scroll',
    description:
      'Effet de parallax et zoom sur une photographie afin de la sublimer !',
    link: '/projets/zoomParallax',
    github: '/',
  },

  {
    image: '/works/cursorHoverMask.gif',
    category: 'nouveaux',
    name: 'Effet de masque',
    description:
      "Un bel effet de masque sur un texte qui affiche un autre contenu au survol.",
    link: '/projets/cursorHoverMask',
    github: '/',
  },
  {
    image: '/works/cardsParallax.gif',
    category: 'nouveaux',
    name: 'Effet de parallax',
    description:
      "Un bel effet de Parallax où des cartes se recouvrent lorsque l'on scrolle sur la page",
    link: '/projets/cardsParallax',
    github: '/',
  },
  {
    image: '/works/infiniteTextMoveOnScroll.gif',
    category: 'nouveaux',
    name: 'Scroll infini de texte',
    description:
      'Texte qui s\'anime à l\'infini et change de sens selon le sens de la visite.',
    link: '/projets/infiniteTextMoveOnScroll',
    github: '/',
  },
  {
    image: '/works/menuPixelsTransition.gif',
    category: 'nouveaux',
    name: 'Menu effet pixelisé',
    description:
      'Menu avec un effet de transition "pixelisé" à l\'ouverture.',
    link: '/projets/menuPixelsTransition',
    github: '/',
  },
  {
    image: '/works/smoothParallaxGallery.gif',
    category: 'Photographe',
    name: 'Gallerie d\'image effet de parallax',
    description:
      'Bel effet de parallax sur une gallerie d\'image lors du scroll .',
    link: '/projets/smoothParallaxGallery',
    github: '/',
  },
  {
    image: '/works/portfolioPhotographGallery.gif',
    category: 'Photographe',
    name: 'Portfolio de Photographe',
    description:
      'Prévisualisation de la gallerie au survol du menu.',
    link: '/',
    github: '/',
  },
  {
    image: '/works/svgBezierCurve.gif',
    category: 'Artistique',
    name: 'Animation d\'élements graphique',
    description:
      'Animation d\'un séparateur lors du survol de celui-ci à la souris.',
    link: '/projets/svgBezierCurve',
    github: '/',
  },
  {
    image: '/works/colorfullTransitions.gif',
    category: 'Artistique',
    name: 'Magnifiques transitions colorées',
    description:
      'Exemple de transitions colorées, animées lors du changement de page.',
    link: '/',
    github: '/',
  },
  {
    image: '/works/textOpacityScroll.gif',
    category: 'Effets visuels',
    name: 'Animation de textes',
    description:
      'Au scroll, l\'opacité peut être animé par bloc, paragraphe ou lettre.',
    link: '/projets/textOpacityScroll',
    github: '/',
  },
  {
    image: '/works/portfolioPhotographeSobre.gif',
    category: 'Photographe',
    name: 'Portfolio de Photographe',
    description:
      'Exemple de portfolio de Photographe, plus sobre, avec effet d\'ouverture.',
    link: '/',
    github: '/',
  },
  {
    image: '/works/creativeButtons.gif',
    category: 'nouveaux',
    name: 'Survol de boutons',
    description: "Nouveaux exemples d'effets au survol de boutons stylisés ",
    link: '/projets/creativeButtons',
    github: '/',
  },
  {
    image: '/works/artistGallery.gif',
    category: 'Photographe',
    name: 'Gallerie d\'artiste',
    description:
      'Effets visuels lors de la visualisation d\'une gallerie de photos artistiques.',
    link: '/',
    github: '/',
  },
  {
    image: '/works/previewHoverMenu.gif',
    category: 'Photographe',
    name: 'Prévisualisation artiste',
    description:
      'Prévisualisation d\'artiste lors du survol du menu.',
    link: '/',
    github: '/',
  },
  {
    image: '/works/portfolioPhotographe2.gif',
    category: 'Photographe',
    name: 'Portfolio de Photographe',
    description:
      'Exemple de prévisualisation de la gallerie au survol du menu.',
    link: '/',
    github: '/',
  },
  {
    image: '/works/cinematicEffect.gif',
    category: 'Artistique',
    name: 'Effet cinématique',
    description:
      'Effets "cinématiques" avec apparitions et distortions de textes et d\'images.',
    link: '/',
    github: '/',
  },
  {
    image: '/works/gallery3dLike.gif',
    category: '3D',
    name: 'Effet 3D',
    description:
      "Effet 3D lors de la visualisation d\'une gallerie d\'images lors du scroll",
    link: '/',
    github: '/',
  },
  {
    image: '/works/geek3d.gif',
    category: '3D',
    name: 'Image effet 3D',
    description:
      'Travail autour de l\'image : profondeur et effet 3D au survol.',
    link: '/',
    github: '/',
  },
  {
    image: '/works/imagesHoverEffect.gif',
    category: 'Effets visuels',
    name: 'Effet zoom stylisé',
    description:
      'Effet de zoom lors du survol d\'images avec en plus, un léger effet de \'glitch\'',
    link: '/',
    github: '/',
  },
  {
    image: '/works/shapesImagesOnScroll.gif',
    category: 'Effets visuels',
    name: 'Application exemple',
    description:
      'Apparition d\'images avec transitions stylisées et formes artistiques lors du scroll.',
    link: '/',
    github: '/',
  },
  {
    image: '/works/cardHoverB&WEffect.gif',
    category: 'Effets visuels',
    name: 'Effets d\'image au survol',
    description:
      'Effet de zoom et teinte monochrome au survol d\'une image + affichage de données.',
    link: '/',
    github: '/',
  },
  {
    image: '/works/galleryXaxisTransitions.gif',
    category: 'Transitions',
    name: 'Transitions d\'images',
    description:
      'Transitions lors de la visualisation d\'une gallerie dans un carousel d\'image.',
    link: '/',
    github: '/',
  },
  {
    image: '/works/galleryPixelTransition.gif',
    category: 'Transitions',
    name: 'Effet minituatures + transition pixelisé',
    description:
      'Rendu de miniatures photos dans un paragraphe + transition pixelisé.',
    link: '/',
    github: '/',
  },
];
