'use client';
import React from 'react';
import Link from 'next/link';
import { Button } from './ui/button';
import { Download, Send } from 'lucide-react';
import { useTheme } from 'next-themes';

import Modal from './Modal';

import {
  RiBriefcase4Fill,
  RiArrowDownSLine,
} from 'react-icons/ri';

import Badge from './Badge';
import DevImg from './DevImg';
import Socials from './Socials';

import {useState} from 'react'

const Hero = () => {
  const { theme, setTheme } = useTheme();

  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedService, setSelectedService] = useState(null);

    const openModal = (service) => {
      setSelectedService(service);
      setModalOpen(true);
    };

    const closeModal = () => {
      setModalOpen(false);
    };

  return (
    <section className='py-12 xl:py-24 h-[84vh] xl:pt-[28px] bg-hero bg-no-repeat bg-bottom bg-cover dark:bg-none'>
      <div className='container mx-auto'>
        <div className='flex justify-between gap-x-8'>
          <div className='flex flex-col max-w-[600px] justify-center mx-auto xl:mx-0 text-center xl:text-left'>
            <h1 className='text-2xl uppercase font-semibold mb-3 text-primary tracking-[4px]'>
              Développeur Web
            </h1>
            <h2 className='h1 mb-4'>Hello, je m'appelle Jérémie Deldem</h2>
            <h3 className='subtitle max-w-[490px] mx-auto xl:mx-0'>
              
              Je donne vie à vos projets et vos idées avec passion et créativité. 
            </h3>
            <div className='flex flex-col gap-y-3 md:flex-row gap-x-3 mx-auto xl:mx-0 mb-12'>
                <Button
                  variant='neo'
                  className='gap-x-2 dark:border-[#6D28D9] dark:border-2 dark:shadow-[5px_5px_0px_0px_#6D28D9]'
                >
              <Link href='/contact' className='flex gap-x-2'>
                  Contactez-moi <Send size={18} />
              </Link>
                </Button>
                <Button
                  onClick={() => openModal()}
                  variant='neo'
                  className='gap-x-2 dark:border-[#6D28D9] dark:border-2 dark:shadow-[5px_5px_0px_0px_#6D28D9]'
                >
                  CV
                  
                  <Download size={18} />
                </Button>
            </div>
            {/* Socials */}
            <Socials
              containerStyles='flex gap-x-6 mx-auto xl:mx-0'
              iconsStyles='text-foreground text-[22px] hover:text-primary transition-all'
            />
          </div>
          <div className='hidden xl:flex relative'>
            {/* BADGE */}
            <Badge
              containerStyles='absolute top-[24%] -left-[5rem] '
              icon={<RiBriefcase4Fill />}
              endCountNum={3}
              badgeText="années d'expérience"
            />
            <div className='bg-hero_shape2_light dark:bg-hero_shape2_dark w-[500px] h-[500px] bg-no-repeat absolute -top-1 -right-2 '></div>
            <DevImg
              containerStyles='bg-hero_shape dark:bg-hero_shape_dark w-[510px] h-[462px] bg-no-repeat relative bg-bottom'
              imgSrc='/hero/avatar2.png'
              
            />
          </div>
        </div>
        <div
          id='scroll!'
          className=' hidden md:flex justify-center mt-32 bottom-44 xl:bottom-12 animate-bounce'
        >
          <RiArrowDownSLine className='text-6xl text-primary' />
        </div>
      </div>

      {isModalOpen && (
        <Modal onClose={closeModal}>
          <h3>MON CV!!</h3>
        </Modal>)}
    </section>
  );
};

export default Hero;
