'use client';
import Link from 'next/link';
import { Button } from './ui/button';

import 'swiper/css';
import 'swiper/css/pagination';

import { Pagination } from 'swiper/modules';
import ProjectCard from './ProjectCard';
import { Swiper, SwiperSlide } from 'swiper/react';

// const projectData = [
//   {
//     image: '/works/zoomParallax.gif',
//     category: 'Effets',
//     name: 'Tropique du Papillon',
//     description:
//       'Effet de parallax et zoom sur une photographie afin de la sublimer !',
//     link: '/projects/zoomParallax',
//     github: '/',
//   },
//   {
//     image: '/works/artist1.gif',
//     category: 'Photographe',
//     name: 'Application exemple',
//     description:
//       'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Numquam iure eaque vitae esse sint iusto provident voluptates ab dolorem voluptatibus.',
//     link: '/',
//     github: '/',
//   },
//   {
//     image: '/works/artist2.gif',
//     category: 'Artistique',
//     name: 'Application exemple',
//     description:
//       'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Numquam iure eaque vitae esse sint iusto provident voluptates ab dolorem voluptatibus.',
//     link: '/',
//     github: '/',
//   },
//   {
//     image: '/works/artist3.gif',
//     category: 'Photographe',
//     name: 'Application exemple',
//     description:
//       'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Numquam iure eaque vitae esse sint iusto provident voluptates ab dolorem voluptatibus.',
//     link: '/',
//     github: '/',
//   },
//   {
//     image: '/works/artist4.gif',
//     category: 'Photographe',
//     name: 'Application exemple',
//     description:
//       'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Numquam iure eaque vitae esse sint iusto provident voluptates ab dolorem voluptatibus.',
//     link: '/',
//     github: '/',
//   },
//   {
//     image: '/works/artist5.gif',
//     category: 'Photographe',
//     name: 'Application exemple',
//     description:
//       'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Numquam iure eaque vitae esse sint iusto provident voluptates ab dolorem voluptatibus.',
//     link: '/',
//     github: '/',
//   },
//   {
//     image: '/works/artist6.gif',
//     category: 'Photographe',
//     name: 'Application exemple',
//     description:
//       'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Numquam iure eaque vitae esse sint iusto provident voluptates ab dolorem voluptatibus.',
//     link: '/',
//     github: '/',
//   },
//   {
//     image: '/works/artistic.gif',
//     category: 'Artistique',
//     name: 'Application exemple',
//     description:
//       'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Numquam iure eaque vitae esse sint iusto provident voluptates ab dolorem voluptatibus.',
//     link: '/',
//     github: '/',
//   },
//   {
//     image: '/works/artistic2.gif',
//     category: 'Artistique',
//     name: 'Application exemple',
//     description:
//       'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Numquam iure eaque vitae esse sint iusto provident voluptates ab dolorem voluptatibus.',
//     link: '/',
//     github: '/',
//   },
//   {
//     image: '/works/geek3d.gif',
//     category: '3D',
//     name: 'Application exemple',
//     description:
//       'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Numquam iure eaque vitae esse sint iusto provident voluptates ab dolorem voluptatibus.',
//     link: '/',
//     github: '/',
//   },
//   {
//     image: '/works/mrC.gif',
//     category: 'Next.JS',
//     name: 'Application exemple',
//     description:
//       'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Numquam iure eaque vitae esse sint iusto provident voluptates ab dolorem voluptatibus.',
//     link: '/',
//     github: '/',
//   },
//   {
//     image: '/works/effects.gif',
//     category: 'Effets visuels',
//     name: 'Application exemple',
//     description:
//       'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Numquam iure eaque vitae esse sint iusto provident voluptates ab dolorem voluptatibus.',
//     link: '/',
//     github: '/',
//   },
//   {
//     image: '/works/effects2.gif',
//     category: 'Effets visuels',
//     name: 'Application exemple',
//     description:
//       'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Numquam iure eaque vitae esse sint iusto provident voluptates ab dolorem voluptatibus.',
//     link: '/',
//     github: '/',
//   },
//   {
//     image: '/works/effects5.gif',
//     category: 'Effets visuels',
//     name: 'Application exemple',
//     description:
//       'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Numquam iure eaque vitae esse sint iusto provident voluptates ab dolorem voluptatibus.',
//     link: '/',
//     github: '/',
//   },
//   {
//     image: '/works/effects4.gif',
//     category: 'Transitions',
//     name: 'Application exemple',
//     description:
//       'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Numquam iure eaque vitae esse sint iusto provident voluptates ab dolorem voluptatibus.',
//     link: '/',
//     github: '/',
//   },
//   {
//     image: '/works/effects3.gif',
//     category: 'Transitions',
//     name: 'Application exemple',
//     description:
//       'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Numquam iure eaque vitae esse sint iusto provident voluptates ab dolorem voluptatibus.',
//     link: '/',
//     github: '/',
//   },
// ];

import { projectData } from '@/app/data/projectData';

const Work = () => {
  return (
    <section className='relative mb-12 xl:mb-48'>
      <div className='container mx-auto'>
        {/* TEXT  */}
        <div className='max-w-[400px] mx-auto xl:mx-0 text-center xl:text-left mb-12 xl:h-[400px] flex flex-col justify-center items-center xl:items-start'>
          <h3 className='section-title mb-4'>Derniers Projets</h3>
          <p className='subtitle mb-8'>
            Voici les derniers projets sur lesquels j'ai travaillé.
          </p>
            <Button
              variant='neo'
              className='dark:border-[#6D28D9] dark:border-2 dark:shadow-[5px_5px_0px_0px_#6D28D9]'
            >
          <Link href='/projets'>
              Tous les projets
          </Link>
            </Button>
        </div>
        {/* SLIDER  */}
        <div className='xl:max-w-[1000px] xl:absolute right-0 top-0 xl:mr-52 '>
          <Swiper
            className='h-[480px]'
            slidesPerView={1}
            breakpoints={{
              640: {
                slidesPerView: 2,
              },
            }}
            spaceBetween={30}
            modules={[Pagination]}
            pagination={{ clickable: true }}
          >
            {/* SEULEMENT LES 4 PREMIERS  */}
            {projectData.slice(0, 4).map((project, index) => {
              return (
                <SwiperSlide key={index}>
                  <ProjectCard project={project} />
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </div>
    </section>
  );
};

export default Work;
